import {iosVhFix} from './utils/ios-vh-fix';
import {getVH} from './utils/vh-fix';
import {initModals} from './modules/modals/init-modals';
import {toggleAccordServices} from './modules/toggleAccordServices';
import {toggleMenuHeader} from './modules/toggleMenuHeader';
import {initMap} from './modules/init-map';
import {toogleFilter} from './modules/toggleFilter';
import {sliderManufacture} from './modules/sliderManufacture';
import {maskPhoneModal} from './modules/maskPhoneModal';
import {modalFormValidate} from './modules/modalFormValidate';
import {formValidateAbout} from './modules/formValidateAbout';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();
  getVH();

  // Modules
  // ---------------------------------

  toggleMenuHeader();
  toggleAccordServices();
  initMap();
  toogleFilter();
  sliderManufacture();
  maskPhoneModal();
  modalFormValidate();
  formValidateAbout();

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.
