/* eslint-disable new-cap */
/* eslint-disable no-undef */

const slideModal = (block) => {
  let modalSliderWrapper = block.querySelector('.modal-slider__images-wrapper');
  const breakpointMobile = window.matchMedia('(min-width:768px)');
  let swDirection = 'horizontal';
  const navBlocks = block.querySelector('.modal-slider__nav');
  const mainBlocks = block.querySelector('.modal-slider__main');
  let sliderMain;

  if (!modalSliderWrapper) {
    return;
  }

  if (modalSliderWrapper) {
    const breakpointChecker = function () {

      if (breakpointMobile.matches) {

        if (modalSliderWrapper.classList.contains('modal-slider__images-wrapper--vertical')) {
          swDirection = 'vertical';
        }

        const sliderNav = new Swiper(navBlocks, {
          spaceBetween: 22,
          slidesPerView: 4,
          loop: true,
          loopedSlides: 'auto',
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          observer: true,
          observeParents: true,
          direction: swDirection,
          init: false,
        });

        sliderNav.init();

        sliderMain = new Swiper(mainBlocks, {
          spaceBetween: 30,
          loop: true,
          observer: true,
          observeParents: true,
          navigation: {
            // nextEl: '.modal-slider__button--next',
            // prevEl: '.modal-slider__button--prev',
          },
          thumbs: {
            swiper: sliderNav,
          },
          init: false,
        });
      } else {
        // eslint-disable-next-line
        sliderMain = new Swiper(mainBlocks, {
          spaceBetween: 30,
          loop: true,
          observer: true,
          observeParents: true,
          navigation: {
            // nextEl: '.modal-slider__button--next',
            // prevEl: '.modal-slider__button--prev',
          },
          init: false,
        });
      }
      sliderMain.init();
    };

    breakpointMobile.addListener(breakpointChecker);
    breakpointChecker();
  }
};


export {slideModal};
