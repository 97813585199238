const modalFormValidate = () => {
  const form = document.querySelector('.modal-callback__form');
  const inputPhone = form.querySelector('input[type="tel"]');
  const blockPhone = document.querySelector('.modal-call__field--phone');
  const button = document.querySelector('.button--callback');
  let regPhone = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/;

  if (form) {

    let addClassError = () => {
      blockPhone.classList.add('modal-callback__field--error');
    };

    button.addEventListener('click', function (evt) {
      if (!regPhone.test(inputPhone.value)) {
        evt.preventDefault();
        addClassError();
      }
    });
  }
};

export {modalFormValidate};
