/* eslint-disable new-cap */
/* eslint-disable no-undef */

import IMask from 'imask';

const maskPhoneModal = () => {
  const form = document.querySelector('.modal-callback__form');

  if (form) {
    IMask(
        document.getElementById('callback-phone'), {
          mask: '+{7} 000 000 00 00',
        });
  }
};

export {maskPhoneModal};
