const toogleFilter = () => {
  let filter = document.querySelector('.filter');
  if (filter) {
    const buttons = document.querySelectorAll('.filter__button');
    buttons[0].classList.add('filter__button--active');

    const removeActiveClass = () => {
      buttons.forEach((child) => {
        child.classList.remove('filter__button--active');
      });
    };

    buttons.forEach((item) => {
      item.addEventListener('click', function (evt) {
        evt.preventDefault();
        removeActiveClass();
        item.classList.add('filter__button--active');

      });
    });
  }
};

export {toogleFilter};
