const initMap = () => {

  const map = document.getElementById('map');
  if (map) {
  // eslint-disable-next-line no-undef
    ymaps.ready(function () {

      // if (!myMap) {
      //   return;
      // }

      // eslint-disable-next-line no-undef
      const myMap = new ymaps.Map('map', {
        center: [55.74704356898413, 37.6468755],
        zoom: 10,
      }, {
        searchControlProvider: 'yandex#search',
      });

      // eslint-disable-next-line no-undef
      const placemark1 = new ymaps.Placemark(myMap.getCenter(), {
        hintContent: 'Центральный офис',
        balloonContent: 'Центральный офис, м. Таганская',
        name: 'Центральный офис',
        address: 'м. Таганская',
      }, {
        iconLayout: 'default#image',
        iconImageHref: 'img/svg/dot.png',
        iconImageSize: [32, 34],
        iconImageOffset: [-5, -38],
      });

      // eslint-disable-next-line no-undef
      const placemark2 = new ymaps.Placemark([55.633810235676286, 37.44024515085133], {
        hintContent: 'Мебельный Центр Румянцево',
        balloonContent: 'Мебельный Центр Румянцево, м. Румянцево',
      }, {
        iconLayout: 'default#image',
        iconImageHref: 'img/svg/dot.png',
        iconImageSize: [32, 34],
        iconImageOffset: [-5, -38],
      });

      myMap.geoObjects
          .add(placemark1)
          .add(placemark2);
    });
  }
};

export {initMap};
