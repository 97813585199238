const sliderManufacture = () => {
  const slider = document.querySelector('.manufacture__slider');
  // eslint-disable-next-line no-undef
  const swiper = new Swiper(slider, {
    loop: true,
    autoHeight: true,
    observer: true,
    grabCursor: true,
    on: {
      'beforeResize': function () {
        let slides = slider.querySelectorAll('.swiper-wrapper');
        slides.forEach((slide) => {
          slide.style.height = '';
        });
      },
    },
    navigation: {
      nextEl: '.manufacture__button--right',
      prevEl: '.manufacture__button--left',
    },
  });

  return swiper;
};

export {sliderManufacture};
