const toggleMenuHeader = () => {
  const pageHeader = document.querySelector('.js-header');
  const body = document.querySelector('body');
  const headerToggle = document.querySelector('.js-menu-toggle');
  const submenuButtons = document.querySelectorAll('.dropdown');
  const viewport = 1023;

  if (!pageHeader) {
    return;
  }

  body.classList.add('there-is--js');

  const closedSubMenu = () => {
    submenuButtons.forEach((item) => {
      item.classList.remove('dropdown--active');
    });
  };

  const closedMenu = () => {
    headerToggle.setAttribute('aria-label', 'Открыть меню');
    closedSubMenu();
  };

  const opendMenu = () => {
    headerToggle.setAttribute('aria-label', 'Закрыть меню');
  };

  const toggleMenu = () => {
    pageHeader.classList.toggle('is-menu-open');
    body.classList.toggle('no-scroll');

    if (pageHeader.classList.contains('is-menu-open')) {
      closedMenu();
    } else {
      opendMenu();
    }
  };

  window.addEventListener('resize', function () {
    if (document.body.clientWidth > viewport) {
      pageHeader.classList.remove('is-menu-open');
      body.classList.remove('no-scroll');
      closedMenu();
    }
  });

  const closePanel = (event) => {
    const context = event;
    submenuButtons.forEach((elem) => {
      if (elem !== context) {
        elem.classList.remove('dropdown--active');
      }
    });
  };

  const toggleSubMenu = (event) => {
    event.target.parentNode.classList.toggle('dropdown--active');
    let eventTarget = event.target.parentNode;
    closePanel(eventTarget);
  };

  body.onclick = function (event) {

    if (event.target.closest('.js-menu-toggle')) {
      toggleMenu();

    } else if (event.target.closest('.main-nav__link') || event.target.closest('.side-nav__link')) {
      toggleSubMenu(event);
    }
  };
};

export {toggleMenuHeader};
