const toggleAccordServices = () => {
  let buttons = document.querySelectorAll('.services__button');
  let explanations = document.querySelectorAll('.services__explanation');

  if (buttons.length) {
    explanations[1].classList.remove('services__explanation--hide');
    explanations[1].classList.add('services__explanation--show');
    buttons[1].classList.add('services__button--active');

    const removeClassActive = () => {
      buttons.forEach((link) => {
        link.classList.remove('services__button--active');
      });
    };

    buttons.forEach((item, index) => {
      item.addEventListener('click', (evt) => {
        evt.preventDefault();
        removeClassActive();
        explanations.forEach((el) => {
          if (el.classList.contains('services__explanation--show')) {
            el.classList.remove('services__explanation--show');
            el.classList.add('services__explanation--hide');
          }
        });
        item.classList.add('services__button--active');
        explanations[index].classList.remove('services__explanation--hide');
        explanations[index].classList.add('services__explanation--show');
      });
    });
  }
};


export {toggleAccordServices};
