const formValidateAbout = () => {
  const form = document.querySelector('.about-form form');
  const button = document.querySelector('.about-form__button');
  const inputs = document.querySelectorAll('.about-form__input');

  if (form) {

    button.addEventListener('click', function (evt) {
      let error = formValidate(form);
      if (error > 0) {
        evt.preventDefault();
      }
    });

    let formValidate = function () {
      let error = 0;

      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        formRemoveError(input);

        if (input.getAttribute('type') === 'text' && input.value === '') {
          formAddError(input);
          error++;
        } else if (input.classList.contains('about-form__label--email')) {
          if (emailTest(input)) {
            formAddError(input);
            error++;
          }
        } if (input.classList.contains('about-form__label--text')) {
          if (input.value === '') {
            formAddError(input);
            error++;
          }
        }
      }
      return error;
    };

    let formAddError = function (input) {
      input.parentElement.classList.add('about-form__item--error');
    };

    let formRemoveError = function (input) {
      input.parentElement.classList.remove('about-form__item--error');
    };

    let emailTest = function (input) {
      return !/([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})/.test(input.value);
    };
  }
};

export {formValidateAbout};
